.grid-list-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(237px, 1fr));
    grid-gap: 20px;
}

.grid-list-container-composer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.grid-list-item {
    align-self: end;
    height: 100%;
}

.grid-list-landing {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(237px, 1fr));
    grid-gap: 20px;
}
