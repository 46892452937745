//
// Dropdown
// --------------------------------------------------

.dropdown-menu {
  border: {
    color: $border-color;
    radius: $border-radius-base;
  }
  font-size: $nav-link-font-size;
  box-shadow: $sub-menu-shadow;
  .dropdown-item {
    padding: {
      right: 20px;
      left: 20px;
    }
    transition: color .3s;
    color: $nav-link-color;
    text-decoration: none;
    &:hover, &.active, &:focus, &:active { background: 0; }
    &:hover { color: $nav-link-hover-color; }
    &.active { color: $nav-link-active-color; }
  }
  a.dropdown-item { font-weight: $nav-link-font-weight; }
}
.dropdown-toggle::after {
  margin: {
    top: 1px;
    left: .3em;
  }
  vertical-align: .2em;
}

// Inside Button Group
.btn.dropdown-toggle::after { vertical-align: .2em; }

// Show Animation
.show .dropdown-menu {
  animation: dropdown-show .25s;
}
@keyframes dropdown-show {
  from { opacity: 0; }
  to { opacity: 1; }
}
