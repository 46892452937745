.suggestion-row:hover {
  background-color: #f4f4f4;
}

.suggestion-row {
  cursor: pointer;
  padding: 8px;
}

.suggestion-not-available {
  font-size: 11px;
  color: rgb(252, 119, 119);
  display: inline-block;
  font-weight: 600;
}

.suggestion-available-ecomm {
  font-size: 12px;
  color: #43d9a3;
  display: inline-block;
  font-weight: bold;
}
