.react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input:focus {
    outline: none;
  }
  
  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
    width: 100%;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: $gray-lighter;
  }
  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 51px;
    border: 1px solid #e1e7ec;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
  }
  
  .react-autosuggest__suggestion:not(:first-child) {
    //border-top: 1px solid #ddd;
  }
  
  .react-autosuggest__suggestion--focused {
    background-color: $gray-lighter!important;
    color: #fff;
  }

  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }

  .name { padding: 5px; margin-left: 5px}

  .react-autosuggest__suggestion--focused {
    color: #120000;
  }