#modal-location-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

#modal-location-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
}

#modal-location-address-bar-container {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}

.ReactModal__Body--open {
  overflow: hidden;
}