//
// Gallery (Photoswipe)
// --------------------------------------------------

// Gallery Item
.gallery-item {
  margin-bottom: 30px;
  > a {
    display: block;
    position: relative;
    width: 100%;
    border-radius: $border-radius-lg;
    text-decoration: none;
    overflow: hidden;
    > img {
      display: block;
      width: 100%;
    }
    &::before {
      @include overlay-block(1, $black-color, 0);
      transition: opacity .3s;
    }
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: -19px;
      transform: translateY(15px);
      transition: all .35s;
      color: $white-color;
      font: {
        family: feather;
        size: 26px;
      }
      text-align: center;
      content: '\e036';
      opacity: 0;
      z-index: 5;
    }
    &:hover {
      &::before { opacity: .45; }
      &::after {
        transform: translateY(0);
        opacity: 1;
      }
    }
    &[data-type='video'] {
      &::after {
        left: 50%;
        width: 46px;
        height: 46px;
        margin: {
          top: -22px;
          left: -22px;
        }
        padding-left: 5px;
        transform: none;
        border-radius: 50%;
        background-color: $white-color;
        color: $gray-dark;
        font-size: 27px;
        line-height: 42px;
        box-shadow: 0px 4px 15px 0px rgba($black-color, .25);
        content: '\e052';
        opacity: 1;
      }
    }
  }
  .caption { display: none; }
  &.no-hover-effect > a::before { display: none; }
}
.grid-no-gap .gallery-item {
  margin-bottom: 0;
  > a { border-radius: 0; }
}
.owl-carousel .gallery-item { margin-bottom: 0; }

// Photoswipe
.pswp__zoom-wrap {
  text-align: center;
  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
.wrapper {
  line-height: 0;
  width: 100%;
  max-width: 900px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}
.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
  width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
video {
  width: 100% !important;
  height: auto !important;
}
.pswp__caption__center {
  padding: 20px 10px;
  font: {
    size: $font-size-sm;
    weight: 500;
  }
  text-align: center;
}