.wl-autocomplete {
  position: relative;
  text-align: left;

  .wl-autocomplete-dropdown {
    background-color: $white-color;
    border-radius: .25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
    position: absolute;
    left: 0;
    right: 0;
    z-index: 40;

    .wl-autocomplete-dropdown-section {
      .wl-autocomplete-dropdown-section-title {
        font-size: $font-size-sm;
        padding: .5rem 1.5rem;
        margin: 0;
      }

      .wl-autocomplete-dropdown-section-item , .list-group-item {
        border-style: solid;
        border-width: 0;
        border-bottom-width: 1px;
        border-color: $border-color;
        cursor: pointer;
        padding: .75rem 1.5rem;
        margin: 0 !important;

        &:last-child {
          border-color: transparent;
        }

        &.wl-autocomplete-dropdown-section-item-active,
        &:hover {
          background-color: $gray-light;
        }

        i {
          font-size: 1em;
        }
      }
    }
  }
}
