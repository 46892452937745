.main-section {
  background-color: $pink-light-color;

  .bg-wave {
    background-image: url(https://cdn.winelivery.com/public/website/wave.svg);
    height: 142.5px;
    margin-top: -42.5px;
    background-size: 1080px 142.5px;
  }
}

.what-is-section {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 70%,
    rgba(245, 245, 245, 1) 30%
  );

  .image-column {
    .what-bg-image {
      background-image: url(https://cdn.winelivery.com/public/website/italy.jpeg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      min-height: 450px;
      height: 100%;
    }
  }
}
