.shop-container {
  .left-column {
    background: #fff;
    width: $sidebar-filters-width;
    position: fixed;
    top: $navbar-min-height;
    padding-bottom: 16px;
    z-index: 1;
    height: calc(100vh - #{$navbar-min-height});

    > .sidebar {
      height: 100%;
      display: flex;
      flex-direction: column;

      > .sidebar-content {
        flex: 1 1 auto;
        overflow-y: auto;
      }
    }
  }

  .product-container {
    flex: 1 1 auto;
  }

  .address-card {
    border-radius: 16px !important;
    background-color: #edb2bf;
  }
}

.sidebar-content {
  .widget {
    margin-bottom: $grid-vertical-step;

    h3.widget-title {
      border-bottom: none;
      margin-bottom: floor($grid-vertical-step / 2);
      padding-bottom: 0;
      padding-top: $grid-vertical-step;
      border-top: 1px solid $border-color;
    }
  }
}

.shop-detail {
  .product-details-column {
    .product-title {
      font-size: 34px;
      letter-spacing: 0.03rem;
    }
    .product-subtitle {
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      color: $gray-dark;
    }
    .product-price {
      font-size: 30px;
      color: #374250;
      font-weight: bold;
    }
  }
}

.wl-product-item {
  padding: 0;
  border-radius: 16px;
  overflow: hidden;

  &:hover {
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08),
      0 0 0 1px rgba(0, 0, 0, 0.04);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.04);
  }

  .product-thumb {
    padding-top: 1rem;

    .item-thumb.item-thumb-wishlist {
      top: 0;
      left: unset;
      right: -10px;
    }
  }

  .product-card-details {
    padding: 1rem;
    /*background-color: #f5f5f5;*/

    .product-title {
      text-align: left;
      font-weight: 500;
      font-size: 16px;
    }

    .product-subtitle {
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0.03rem;
      font-weight: normal;
      font-size: 13px;
      padding: 0.5rem 0;
      margin: 0;
    }

    .product-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.25rem;

      .product-price {
        margin: 0;
        font-size: 18px;
        font-weight: 500;

        .product-currency {
          font-weight: normal;
          font-size: 13px;
          margin-right: 0.25rem;
        }
      }

      .product-btn {
        padding: 0 8px;
        text-transform: none;
        margin: 0;
        font-size: 13px;
        border-radius: 8px;
        letter-spacing: 0.03rem;
      }

      .product-quantity {
        max-width: 150px;

        input {
          border-radius: 8px;
        }
      }
    }
  }
}

@media (max-width: $screen-lg) {
  .shop-container {
    .left-column {
      position: initial !important;
      width: 0;
      margin: 0 !important;
      padding: 0 !important;

      .sidebar {
        display: none;
      }
    }

    .product-container {
      margin-left: 0 !important;
    }
  }
}

@media (max-width: $screen-md) {
  .shop-container {
    .address-card {
      h2 {
        font-size: 24px;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 12px;
        margin-bottom: 0;
      }
      &::before {
        position: absolute;
        display: block;
        content: '';
        border-color: transparent;
        border-style: solid;
        bottom: -8px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: #edb2bf;
        left: 16px;
        z-index: 999;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .shop-container {
    .grid-list-container {
      padding: 0 0.5rem;
    }
  }

  .wl-product-item {
    border-radius: 0;

    &:hover {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .product-title {
      text-align: left;

      a {
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 0.03rem;
      }
    }

    .product-subtitle {
      text-align: left;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.1rem;
      margin: 0.5rem 0;
      color: $body-color;
    }

    .product-price {
      text-align: left;
      font-weight: 600;
      font-size: 18px;
    }

    .product-btn {
      padding: 0 8px;
      text-transform: none;
      margin: 0;
      font-size: 13px;
      border-radius: 8px;
      letter-spacing: 0.03rem;
    }

    .product-quantity {
      max-width: 150px;

      input {
        border-radius: 8px;
      }
    }
  }
}

@media (max-width: $screen-xs) {
  .wl-product-item {
    .product-title {
      a {
        font-size: 16px;
      }
    }

    .product-subtitle {
      font-size: 13px;
    }

    .product-price {
      font-size: 18px;
    }
  }
}
