//
// Forms
// --------------------------------------------------

.form-control {
  padding: 0 18px 3px;
  border: 1px solid darken($border-color, 2%);
  border-radius: ceil($input-height / 2);
  background-color: $white-color;
  color: $gray-dark;
  font: {
    family: $font-family-base;
    size: $font-size-sm;
  }
  @include appearance(none);
  &:not(textarea) { height: $input-height; }

  // Placeholder
  @include placeholder;

  // Focus state
  &:focus {
    border-color: $brand-primary;
    outline: none;
    color: $gray-dark;
    box-shadow: none !important;
  }
  &[type='color'] { padding-bottom: 0 !important; }

  // Disabled
  &:disabled, &[readonly] {
    background-color: $gray-lighter;
    cursor: not-allowed;
  }
}

// Textarea
textarea.form-control {
  padding: {
    top: 12px;
    bottom: 12px;
  }
}

// Select
select.form-control {
  padding-right: 38px;
  background: {
    position: center right 17px;
    image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4Ni45MzUsNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiwwLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0ICAgQzEuODA3LDcyLjk5OCwwLDc3LjI3OSwwLDgyLjIyOGMwLDQuOTQ4LDEuODA3LDkuMjI5LDUuNDI0LDEyLjg0N2wxMjcuOTA3LDEyNy45MDdjMy42MjEsMy42MTcsNy45MDIsNS40MjgsMTIuODUsNS40MjggICBzOS4yMzMtMS44MTEsMTIuODQ3LTUuNDI4TDI4Ni45MzUsOTUuMDc0YzMuNjEzLTMuNjE3LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N0MyOTIuMzYyLDc3LjI3OSwyOTAuNTQ4LDcyLjk5OCwyODYuOTM1LDY5LjM3N3oiIGZpbGw9IiM2MDY5NzUiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
    repeat: no-repeat;
    size: 9px 9px;
  }
  &:not([size]):not([multiple]) {
    height: $input-height;
  }
}

// Form group
.form-group {
  margin-bottom: floor($grid-vertical-step / 1.2) !important; //~20px
  label {
    margin-bottom: 8px;
    padding-left: 18px;
    font: {
      size: $font-size-xs;
      weight: 500;
    }
    cursor: pointer;
    &.col-form-label {
      align-self: center !important;
      margin-bottom: 0;
    }
  }
}

// Input sizes
.form-control-lg {
  border-radius: ceil($input-height-lg / 2);
  font-size: $font-size-base;
  &:not(textarea) { height: $input-height-lg; }
  &.form-control-rounded { border-radius: $border-radius-lg; }
  &.form-control-square { border-radius: 0; }
}
select.form-control.form-control-lg {
  &:not([size]):not([multiple]) {
    height: $input-height-lg;
  }
}
.form-control-sm {
  padding-bottom: 2px;
  border-radius: ceil($input-height-sm / 2);
  &:not(textarea) { height: $input-height-sm; }
  &.form-control-rounded { border-radius: $border-radius-sm; }
  &.form-control-square { border-radius: 0; }
}
select.form-control.form-control-sm {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}

// Form text
.form-text { padding-left: 18px; }

// Checkboxes & Radio buttons
.custom-control {
  margin-bottom: 6px !important;
  padding-left: 1.5rem !important;
  &:focus { outline: 0; }
  .custom-control-label {
    margin: 0;
    padding-left: 0;
    font: {
      size: $font-size-sm !important;
      weight: normal !important;
    }
    text-transform: none;
    &::before { box-shadow: none !important; }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $brand-primary;
    background-color: $brand-primary;
  }
  .custom-control-input:disabled ~ .custom-control-label {
    color: $gray;
    cursor: not-allowed;
  }
}
.custom-control-inline { align-items: center; }

// File input
.custom-file,
.custom-file-input {
  height: $input-height;
  border-radius: ceil($input-height / 2);
  cursor: pointer;
  &:focus ~ .custom-file-label { box-shadow: none; }
}
.custom-file-label { font-weight: normal !important; }
.custom-file-label,
.custom-file-label::after {
  height: $input-height;
  border-radius: ceil($input-height / 2);
  border-color: darken($border-color, 2%);
  color: $gray-dark;
  line-height: 2.2;
}
.custom-file-label::after {
  height: ($input-height - 2);
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background-color: $gray-lighter;
  font-weight: 500 !important;
}

// Input shapes
.form-control-rounded {
  border-radius: $border-radius-base;
  .custom-file-control,
  .custom-file-control::before { border-radius: $border-radius-base; }
}
.form-control-square {
  border-radius: 0;
  .custom-file-control,
  .custom-file-control::before { border-radius: 0; }
}

// Input group
.input-group {
  display: block;
  position: relative;
  .input-group-addon,
  .input-group-btn {
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .input-group-addon {
    left: 15px;
    transition: color .3s;
    background-color: transparent !important;
    color: $gray;
  }
  .form-control {
    padding-left: 35px;
    &:focus ~ .input-group-addon { color: $brand-primary; }
  }
  .input-group-btn {
    right: 10px;
    margin-top: -2px;
    button {
      transition: color .3s;
      border: 0;
      background: 0;
      color: $gray-dark;
      font-size: 1.2em;
      cursor: pointer;
      &:hover { color: $brand-primary; }
    }
    .btn {
      margin: 0;
      margin-right: 3px;
      padding: 0;
    }

    &.input-group-btn--start {
      left: 10px;
      right: unset;
      .btn {
        margin: 0 0 0 3px;
      }
    }
  }
  .input-group-btn ~ .form-control {
    padding: {
      right: 35px;
      left: 18px;
    }
  }
  .input-group-btn.input-group-btn--start ~ .form-control {
    padding: {
      left: 35px;
    }
  }
}

// Light Skin
.input-light {
  &.form-control,
  .form-control {
    border-color: rgba($white-color, .17);
    background-color: transparent;
    color: $white-color;
    @include placeholder(rgba($white-color, .5));
    &:focus {
      border-color: $brand-primary;
      color: $white-color;
    }
  }
  &.input-group {
    .input-group-addon { color: rgba($white-color, .5); }
  }
}

// Subscription form
.subscribe-form {
  .input-group {
    width: calc(100% - 88px);
    float: left;
  }
  .btn {
    margin: 0;
    float: right;
    > i {
      margin-top: -6px;
      font-size: 1.5em;
    }
  }
  .form-text { padding-top: floor($grid-vertical-step / 2); }
}

// Coupon form
.coupon-form {
  .form-control {
    display: inline-block;
    width: 100%;
    max-width: 235px;
    margin-right: 12px;
  }
  .btn { margin-right: 0; }
  @media (max-width: $screen-md) {
    .form-control {
      display: block;
      max-width: 100%;
    }
  }
}

// Login Box
.login-box {
  display: block;
  width: 100%;
  padding: 0 16px;
  //border: 1px solid $border-color;
  border-radius: $border-radius-lg;
}

// Interactive Credit Card
.card-wrapper { margin: 30px 0; }
@media (max-width: $screen-sm) {
  .jp-card-container { width: 285px !important; }
  .jp-card { min-width: 250px !important; }
}

// Validation states
.was-validated {
  .form-control,
  .form-control-light-skin {
    &:valid,
    &.is-valid {
      border-color: darken($border-color, 1%) !important;
      &:focus { border-color: $brand-primary !important; }
    }
    &:invalid,
    &.is-invalid {
      border-color: $brand-danger !important;
      &:focus { border-color: $brand-primary !important; }
    }
  }
  .custom-file-input:valid ~ .custom-file-label,
  .custom-file-input.is-valid ~ .custom-file-label {
    border-color: darken($border-color, 1%) !important;
  }
  .custom-control-input {
    &:valid,
    &.is-valid {
      & ~ .custom-control-label {
        color: $body-color;
        &::before { background-color: #dee2e6; }
      }
      &:checked {
        & ~ .custom-control-label {
          color: $body-color;
          &::before {
            border-color: $brand-primary;
            background-color: $brand-primary;
          }
        }
      }
    }
  }
}
.valid-feedback,
.invalid-feedback {
  margin-top: 5px;
  padding-left: 18px;
  font-size: 90%;
}
.custom-control {
  .valid-feedback,
  .invalid-feedback,
  .valid-tooltip,
  .invalid-tooltip {
    margin-left: 0;
    padding-left: 0;
  }
}
.valid-tooltip,
.invalid-tooltip {
  margin: {
    top: -1px;
    left: 18px;
  }
  padding: {
    top: 2px;
    bottom: 2px;
  }
  border-radius: 0;
  font-size: 90%;
}

.prepended-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 1px;
}
