.home {
  .offcanvas-wrapper {
    background: {
      color: $white-color;
      //image: url("../img/hero/vectorpaint.svg");
      position: top right;
      repeat: no-repeat;
      size: auto;
    }

    .input-group {
      .input-group-btn {
        margin-top: 0;
        right: 5px;

        button {
          background-color: $brand-primary;
          padding: 10px 20px;
          border-radius: 999px;
          color: $white-color;

          i {
            margin-top: -5px;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-lg) {
  .home {
    .download-app-section {
      .bg-download-img {
        background: none;
      }
    }
  }
}
