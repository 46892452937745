//
// Drawer Menu
// --------------------------------------------------

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  /*background: #bdc3c7;*/
  color: white;
  font-size: 25px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  top: 0;
  width: $drawer-width !important;
}

/* General sidebar styles */
.bm-menu {
  background: $white-color;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $white-color;
}

/* Wrapper for item list */
.bm-item-list {
  //
}

/* Individual item */
.bm-item {
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  top: 0;
  left: 0;
}

#drawer-account {
  ul {
    padding: 0;
    margin: 0;

    > li {
      display: block;
      position: relative;
      > span {
        display: block;
        padding: 5px 0px !important;
        cursor: pointer;
        transition: color .3s;
        color: $nav-link-color;
        font: {
          size: $nav-link-font-size;
          weight: $nav-link-font-weight;
        }
        text: {
          align: left;
          decoration: none;
        }
        > i {
          display: inline-block;
          margin: {
            top: -2px;
            right: 6px;
          }
          font-size: .9em;
          vertical-align: middle;
        }
      }
      &.sub-menu-user {
        display: table;
        width: 100%;
        padding: 4px 17px 6px;
        .user-ava,
        .user-info {
          display: table-cell;
          vertical-align: middle;
        }
        .user-ava {
          width: 44px;
          > img {
            display: block;
            width: 44px;
            padding: 3px;
            border: 1px solid $border-color;
            border-radius: 50%;
          }
        }
        .user-info {
          padding-left: 8px;
          > .user-name {
            margin-bottom: 2px;
            font-size: $font-size-sm;
          }
          > span { display: block; }
        }
      }
      &.sub-menu-separator {
        margin: {
          top: 5px;
          bottom: 3px;
        }
        border-top: 1px solid $border-color;
      }
      &:hover > a { color: $nav-link-hover-color; }
      &.active > a { color: $nav-link-active-color; }
      &.has-children {
        > a {
          padding-right: 35px !important;
          &::after {
            display: block;
            position: absolute;
            top: 50%;
            right: 18px;
            width: 0;
            height: 0;
            margin-top: -4px;
            border-top: .3em solid transparent;
            border-bottom: .3em solid transparent;
            border-left: .32em solid;
            content: '';
          }
        }
      }
      > .sub-menu {
        top: -4px;
        left: 100%;
        margin-left: -5px;
      }
    }
  }
}

.toolbar-drawer-item {
  > .account + div,
  > .cart + div {
    display: inline-block;
  }
  > .cart {
    background-color: $brand-success;
    color: #FFF;
    font-weight: bold;
  }
  > :hover.cart {
    color: $brand-success;
    font-weight: bold;
  }
}