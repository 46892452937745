/*.pac-container {
  border-radius: 3px;
  padding:0px;
  display: block;
}


.hdpi.pac-logo:after {
  background-image: none;
}

.pac-logo:after {
  background-image: none;
}
*/

.pac-item-query {
  font-size: $font-size-base;
  font-family: $font-family-base;
  clear: both;
  display: block;
  padding: 0px;
  margin-bottom: -10px;
}

.pac-matched {
  font-size: $font-size-base;
  font-family: $font-family-base;
  font-weight: normal;
  //color: #606975 !important;
}

.pac-item {
  font-size: $font-size-base;
  font-family: $font-family-base;
  padding: 0px 10px 0px 10px;
  color: #606975 !important;
}

.pac-icon {
  display: none;
}

.addressbar-tabs {
  border-bottom-color: transparent;

  .nav-link {
    padding: 0 12px 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    letter-spacing: normal;
    text-transform: none;

    &.active {
      border-color: transparent;
      border-bottom-color: #cf1d44;
      color: #cf1d44;
      border-bottom-width: 2px;
    }
  }
}
