// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
// Extra small devices (portrait phones, less than 576px)
@media (min-width: 575.98px) {
	.fai-un-regalo {
		& .card-text {
			font-size: 16px !important;
		}
	}

	.col-xs-1\/5 {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
	.product-card .product-overlay {
		font-size: 14px;
	}

	/* Home */
	.home {
		& .h2 {
			font-size: 27px;
		}
	}
	.fai-un-regalo {
		& .card-text {
			font-size: 14px !important;
		}
	}
	.col-sm-1\/5 {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	.filter-string {
		font-size: 14px !important;
	}

	/* Home */

	.home {
		& .h2 {
			font-size: 38px;
		}
	}

	.col-md-1\/5 {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
	.product-card .product-overlay {
		font-size: 15px;
	}

	.col-lg-1\/5 {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	.section-card-productlist {
		& h3 {
			font-size: 16px;
		}
	}
	.site-menu ul {
		margin-left: 170px;
	}
	.giftcard-card {
		height: 300px;
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.nav-pills > .nav-item {
		-ms-flex-preferred-size: 0 !important;
		flex-basis: 0 !important;
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
		text-align: center !important;
		& > .nav-link {
			margin: 0;
		}
	}
	.product-details-column {
		& h2 {
			font-size: 24px;
		}
		& h4 {
			font-size: 18px;
		}
	}

	.prepended-select {
		background-position: center right 0px !important;
	}
	.location-button {
		height: 44px;
	}

	.subheader-sticky {
		position: sticky;
		top: 84px;
		z-index: 2;
		background-color: white;
		border-bottom: 1px solid #e1e7ec;
	}

	.static-page {
		& p {
			font-size: 14px;
			text-align: justify;
		}
		& li {
			font-size: 14px;
		}
	}
}

@media (max-width: 575.98px) {
	.btn-sm-block {
		display: block;
		width: 100%;
		margin: {
			top: floor($grid-vertical-step / 2); //~12px;
			right: 0 !important;
			bottom: floor($grid-vertical-step / 2); //~12px
			left: 0 !important;
		}
		padding: {
			right: 15px !important;
			left: 15px !important;
		}
	}
	.product-card {
		border-left: none;
		border-right: none;
		border-bottom: none;
		& .product-title a {
			font-size: 15px;
		}
		& .product-overlay {
			font-size: 12px;
		}
	}
	.product-container {
		padding-left: 0;
		padding-right: 0;
	}
	.item-thumb.item-thumb-wishlist {
		left: -10px;
	}

	.aboutPage img {
		padding-top: 12px;
	}

	.page-title > .breadcrumbs {
		padding-top: 0 !important;
		text-align: left !important;
	}

	/* HOME */

	.home {
		& .h2 {
			font-size: 28px;
		}
		& .h4 {
			font-size: 18px;
		}
	}

	/* LANDING */

	.landing {
		& h1 {
			font-size: 26px;
		}
		& h4 {
			font-size: 16px;
		}
	}

	.table td {
		padding-right: 4px;
		padding-left: 4px;
	}
	.table tr td:first-child {
		padding-left: 8px;
	}
	.breadcrumbs {
		& h3 {
			font-size: 18px;
			margin-bottom: 0;
		}
		& h4 {
			font-size: 16px;
		}
		& h5 {
			font-size: 14px;
		}
	}
	.search-string {
		font-size: 16px;
	}

	.toolbar {
		& .cart,
		& .account {
			height: 38px;
			& i {
				line-height: 32px;
			}
		}
	}

	.address-h5 {
		font-size: 16px;
	}
	.address-list {
		border: 0;
		border-top: 1px solid #e1e7ec;
		padding-right: 2px;
		padding-left: 2px;
	}

	.checkout h4 {
		font-size: 17px;
	}
	.checkout-steps {
		margin-bottom: 16px;
		& a {
			border-left: 1px solid #e1e7ec;
			border-radius: 0 !important;
		}
		& span {
			font-size: 12px;
		}
	}
	.checkout-footer {
		margin-top: 0;
	}
	.giftcard-card {
		height: 250px;
	}

	.grid-list-container {
		display: inherit;
		grid-template-columns: none;
		grid-gap: 0;
	}
	.grid-list-container-composer {
		display: inherit;
		grid-template-columns: none;
		grid-gap: 0;
	}
}
