/* -------------------------- CUSTOM STYLES ----------------------------- */

@font-face {
  font-family: 'KnockoutCruiserWeight';
  src: url('/src/styles/fonts/knockout/knockout-cruiserweight.otf');
}
@font-face {
  font-family: 'KnockoutFlyWeight';
  src: url('/src/styles/fonts/knockout/knockout-flyweight.otf');
}
@font-face {
  font-family: 'KnockoutFullSumo';
  src: url('/src/styles/fonts/knockout/knockout-fullsumo.otf');
}
@font-face {
  font-family: 'KnockoutJuniorFlyWeight';
  src: url('/src/styles/fonts/knockout/knockout-juniorflyweight.otf');
}
@font-face {
  font-family: 'KnockoutJuniorSumo';
  src: url('/src/styles/fonts/knockout/knockout-juniorsumo.otf');
}

.highlight-text {
  background-color: #cf1c44 !important;
  color: #fff;
  padding: 0px 5px 0px 5px;
  border-radius: 2px;
  font-family: 'KnockoutCruiserWeight';
}

.item-thumb {
  position: absolute;
  display: inline-block;
  &.item-thumb-wishlist {
    top: -10px;
    left: -20px;
  }
}

a {
  cursor: pointer;
}

.wishlist-button-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 16px 0 16px 0;
  background-color: white;
  //border: 2px solid lightgray;
  border-radius: 50px;
}

/*look in components/FooterCategories.tsx */
.oblo {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5;
}

.section-card {
  height: 200px;
  cursor: pointer;
  & h3 {
    position: absolute;
    bottom: 12px;
    color: white;
    font-weight: 700;
    right: 22px;
    text-shadow: 1px 1px grey;
    font-size: 30px;
  }
}

.section-card-productlist {
  height: auto;
  & h3 {
    font-size: 16px;
    bottom: -4px;
    right: 14px;
  }
}

.order-div {
  cursor: pointer;
  border: 1px solid $gray-light;
  text-align: center;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 500;
  transition: background-color 0.3s;
  &.active {
    background-color: $gray-light !important;
    color: $gray-dark;
  }
  &:hover {
    background-color: $gray-lighter;
  }
}

.text-cut {
  //display: inline-block;
  //width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.static-page {
  & p {
    font-size: 16px;
    //text-align: justify;
  }
  & li {
    font-size: 16px;
  }
}

.product-list-carousel {
  & .owl-prev,
  .owl-next {
    margin-top: -16px;
    width: 34px;
    height: 34px;
    line-height: 30px;
  }
}

/* --- DRAWER --- */

.drawer-link {
  border-bottom: 1px solid $gray-light;
  cursor: pointer;
}

.drawer-sub-link {
  border-bottom: 1px solid $gray-light;
  width: 92%;
  cursor: pointer;
}

.drawer-account-li {
  font-size: 14px;
  font-weight: 500;
}

.bm-menu {
  padding: 0;
}

.user-list {
  & .drawer-link {
    padding-top: 4px;
    padding-bottom: 4px;
    & span {
      padding-left: 8px !important;
      & i {
        padding-right: 4px;
      }
    }
  }
}

/* --- LIST --- */

.sidebar-content {
  font-size: 16px !important;
}

.filter-string {
  font-size: 16px !important;
  font-weight: 300 !important;
  text-transform: capitalize;
}

.no-collapse-filter {
  display: block;
  position: relative;
  color: #606975;
  text-decoration: none;
}

/* -------------------------- OVERRIDES ----------------------------- */

/* GENERAL */

.offcanvas-wrapper {
  min-height: auto;
}

/* LIST AND ITEMS */

.list-group > .list-group-item {
  padding-right: 10px;

  &.active {
    background-color: #cf1c44 !important;
    color: white;
  }
  &:hover {
    background-color: #cf1c44 !important;
    border-color: #cf1c44 !important;
    color: white;
  }
}

.custom-overlay {
  position: relative; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}

.widget-title {
  color: #374250;
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 10px;
}

.product-card {
  height: 100%;
  & .product-thumb {
    margin-bottom: 0;
    &img {
      width: auto !important;
      margin: auto !important;
    }
  }
  & .product-buttons {
    padding: 8px 0 8px;
    text-align: center;
  }
  & .product-title {
    margin-bottom: 0px;
    font-size: 15px;
  }
}

.scroll-to-top-btn {
  right: 35px;
  z-index: 89;
  &.visible {
    bottom: 105px !important;
  }
}

/* CHECKOUT */

.address-list:hover {
  background-color: #f5f5f5;
}

.form-group label {
  padding-left: 2px;
}

.crea-cocktail .checkout-steps a {
  width: 25%;
}

.signup .checkout-steps a {
  width: 25% !important;
  cursor: default;
}

/* HOME */

blockquote {
  border-top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

blockquote::before {
  color: #cf1c44 !important;
}

blockquote p {
  font-size: 14px;
  margin-bottom: 8px;
}

/* ------ HEADER ------ */
.nav-pills .nav-item .nav-link {
  border-radius: 4px;
}

/* ----- FOOTER ----- */
.subscribe-form .input-group {
  width: auto;
  float: none;
}

/* --- Filters --- */
h3.widget-title {
  border-bottom: 1px solid #e1e7ec;
  padding-bottom: 12px;
}

.navbar.navbar-stuck {
  box-shadow: 0 3px 5px 0 rgba(47, 56, 68, 0.1) !important;
}

/* STRIPE OVERRIDES */

.stripe-form-control {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

/* STEPS */

.steps .step {
  margin-bottom: 0px;
}

.step-icon {
  background-color: #cf1c44 !important;
  color: white !important;
}

.step-title {
  font-size: 20px;
}

/* BUTTONS */

.location-button {
  padding: 0px 0 0px 0;
}

.hamburger-icon {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  transition: background-color 0.35s;
  border: 1px solid #e1e7ec;
  border-radius: 4%;
  color: #606975;
  font-size: 17px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
}

/* GOOGLE AUTOCOMPLETE */

.pac-container {
  z-index: 9201 !important;
}

.col-xs-1\/5,
.col-sm-1\/5,
.col-md-1\/5,
.col-lg-1\/5 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.city-col {
  &:hover {
    color: $link-hover-color;
    cursor: pointer;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
}
