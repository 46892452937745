.checkout {
  .widget-title {
    border-bottom: none;
    padding-bottom: 0;
    text-transform: none;
    font-size: $font-size-base;
  }
}

.checkout-steps-col {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.checkout-resume-col {
  height: 100%;
  margin-left: 1rem;
  padding-left: 3rem;
  border-left: 1px solid $border-color;

  .sidebar {
    position: sticky;
    top: 84px;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.checkout-steps {
  & a.active {
    background-color: $pink-smooth-color;
    color: $brand-primary;
    & + a .angle {
      background-color: $pink-smooth-color;
    }
    & .angle::after {
      border-left-color: $pink-smooth-color;
    }
  }
}

.checkout-selection-item {
  padding: .75rem 1.25rem;
  border-radius: .5rem;
  border: 1px solid $border-color;

  &.checkout-selection-item-hoverable:hover {
    background-color: $gray-lighter;
  }

  .checkout-selection-item-main {
    cursor: pointer;
  }
}

.checkout-grid {
  .checkout-grid-row {
    .checkout-grid-cell {
      padding-top: .75rem;
      padding-bottom: .75rem;
      border-top: 1px solid $border-color;
    }

    &:first-child {
      .checkout-grid-cell {
        border-top: 0;
      }
    }
  }
}

.checkbox-column {
  width: 50px;
}

.payment-selection-item {
  border-radius: .5rem;
  border: 1px solid $border-color;
  cursor: pointer;

  &:hover {
    background-color: $gray-lighter;
  }
  &.active {
    background-color: $pink-light-color;
    border-color: $brand-primary;
    cursor: default;
  }
}

@media (max-width: $screen-lg) {
  .checkout-resume-col {
    margin-left: 0;
    padding-left: 1rem;
  }
}
