.errorpage {
  & h1, p {
    color: #cf1c44;
  }
  & p {
    font-size: 16px;
  }
  & .error-bolle {
    position: relative;
    top: 40px;
  }

  @media (max-width: 575.98px) {
    h1 {
      font-size: 35px;
    }
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 42px;
    }
    p {
      font-size: 20px;
    }
  }
  @media (min-width: 992px) {
    h1 {
      font-size: 55px;
    }
  }
  @media (min-width: 1200px) {
    & h1 {
      font-size: 65px;
    }
  }
}
